import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import Twenty from "react-twentytwenty";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/rtx3060"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/rtx3060.png"}) { ...eyecatchImg },
    rtxoff: file(relativePath: { eq: "gpu/rtxoff.png"}) { ...normalImg },
    rtxon: file(relativePath: { eq: "gpu/rtxon.png"}) { ...normalImg },
    dlss_sampling: file(relativePath: { eq: "gpu/dlss_sampling.png"}) { ...normalImg },
    dlss: file(relativePath: { eq: "gpu/dlss.png"}) { ...normalImg },
    rtx3060_perf_hq: file(relativePath: { eq: "gpu/rtx3060_perf_hq.png"}) { ...normalImgWithHQ },
    turing: file(relativePath: { eq: "gpu/turing.png"}) { ...normalImg },
    rtx3060_spec: file(relativePath: { eq: "gpu/rtx3060_spec.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SaleLoadable = makeShortcode("SaleLoadable");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`RTX 3060はレイトレーシング時代のニュースタンダードGPU`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="rtx3060" mdxType="Image" />
    <p>{`GTX1060はほぼ全てのFullHDゲームに対して、高画質で十分滑らかな基準である60FPSを実現できる優れたGPUであった。`}</p>
    <p>{`ところが毎フレームごとに光の反射経路を計算してより現実に近い描画を行うリアルタイムレイトレーシング(RTX)の技術がゲームで使われるようになった事により、これを有効にしたゲームでは60FPSの実現が困難となった。`}</p>
    <p>{`2021年に発売された`}<strong parentName="p"><em parentName="strong">{`Geforce RTX 3060はFullHDのゲームをリアルタイムレイトレーシングをオンにした状態で遊ぶのに適した新スタンダードモデル`}</em></strong>{`といえる。`}</p>
    <p>{`本記事で言いたいことはこれが全てであるが、GTX 1060、RTX 2060との違いが分からない、あるいはRTX 3070、3080のようなハイエンドな製品を検討している人へ、DLSSなどバックグラウンドの技術も含めてより詳細な情報を提供する。`}</p>
    <p>{`最終的には`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`と合わせて最終的に決めていけば良いだろう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "RTXでの描画は従来のものと次元が異なる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#RTX%E3%81%A7%E3%81%AE%E6%8F%8F%E7%94%BB%E3%81%AF%E5%BE%93%E6%9D%A5%E3%81%AE%E3%82%82%E3%81%AE%E3%81%A8%E6%AC%A1%E5%85%83%E3%81%8C%E7%95%B0%E3%81%AA%E3%82%8B",
        "aria-label": "RTXでの描画は従来のものと次元が異なる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTXでの描画は従来のものと次元が異なる`}</h2>
    <p>{`コンピュータグラフィックスの世界で従来のレンダリングは、光源と物体の単純な位置関係のみに着目して影や映り込みの描画を行ってきた。`}</p>
    <p>{`しかし実際には光源から来た光が物体に反射して、新たな光源となり、その光がまた他の物体に反射して、というように光は反射を繰り返しており、この`}<strong parentName="p">{`光の経路を辿らないことには現実世界に近づくことはできない`}</strong>{`。`}</p>
    <p>{`これを実現する技術がレイトレーシング(Ray Tracing)である。光は英語でRay、辿るはTraceなので、文字通り光の軌跡を辿ることにより物体の情報を収集して描画に反映させる技術となる。`}</p>
    <p>{`映像作品と違い、ゲームではリアルタイムで行わなければならないため`}<strong parentName="p"><em parentName="strong">{`リアルタイムレイトレーシング(RTX)`}</em></strong>{`と呼ばれている。そしてこの技術を搭載したGPUがNvidiaのRTXシリーズである。`}</p>
    <p>{`リアルタイムレイトレーシングによるリアリティの差は非常に大きく、例えばMineCraftを例にとると次のようになる。まずはRTXがOFFの状態。`}</p>
    <Image {...props} name="rtxoff" caption="rtxがoffの状態" mdxType="Image" />
    <p>{`十分といえば十分かもしれないが、全体的にのっぺりとした印象であり、現実味がかけている感は否めない。次にRTXがONの状態。`}</p>
    <Image {...props} name="rtxon" caption="rtxがonの状態" mdxType="Image" />
    <p>{`水面に建物や雲がしっかりと映り込み、また太陽は恐らく建物の後ろから挿しているが、水面からの拡散光により建物が明るく映っている。`}<strong parentName="p"><em parentName="strong">{`より現実感が増した`}</em>{`ことが見て取れる`}</strong>{`だろう。`}</p>
    <p>{`リアルタイムレイトレーシングはゲームグラフィックの世界では大きなブレイクスルーであり、没入感を格段に向上させることができる技術である。`}</p>
    <p>{`ただし、膨大な光の反射を計算しなければならないため、計算量は桁が違い、RTX 3060であってもまだ難しいところがある。`}</p>
    <h2 {...{
      "id": "AI技術DLSSによりRTXの計算量を下げる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#AI%E6%8A%80%E8%A1%93DLSS%E3%81%AB%E3%82%88%E3%82%8ARTX%E3%81%AE%E8%A8%88%E7%AE%97%E9%87%8F%E3%82%92%E4%B8%8B%E3%81%92%E3%82%8B",
        "aria-label": "AI技術DLSSによりRTXの計算量を下げる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AI技術DLSSによりRTXの計算量を下げる`}</h2>
    <p>{`RTXの計算量は、RTX専用のプロセッサーを持つ30xx系の処理能力をもってしても膨大である。この問題をNvidiaはDLSSというAI技術により解決している。`}</p>
    <p>{`DLSSとは(Deep Learning Super Sampling)の略であり、一部のピクセルのみ取り出して(=samplingをおこなって)RTXの計算をおこない、残りのピクセルはAI技術により周りのピクセルの色から推測するという方法である。`}</p>
    <Image {...props} name="dlss_sampling" caption="サンプリングイメージ" mdxType="Image" />
    <p>{`やや専門的な話となるが、AI計算で行うニューラルネットワークの推論演算は、科学技術演算のような精密さを求められないこともあり、16bitなどbit数が少ない浮動小数点形式で数値表現がされている。`}</p>
    <p>{`この計算を行う回路は単純で済むため、コア数を容易に増やせ、その数は100を超える。`}</p>
    <p>{`要するに単純な計算を大量にこなすAI専用プロセッサーを用いて、残りのピクセルの色を計算するのである。`}</p>
    <p>{`この技術により、`}<strong parentName="p"><em parentName="strong">{`オリジナル以上の画質をより少ない計算量で実現できる`}</em></strong>{`ことになった。推論が苦手なシーンもあり、その一点のみが捉えられて画質が落ちると論じられることもあるが、全体として考えればリアルに劣るということはない。`}</p>
    <Image {...props} name="dlss" caption="dlssによる補完" mdxType="Image" />
    <p>{`例はNvidiaのRTX3000シリーズの製品発表会で示されていたゲームのワンシーンであるが、DLSSをOffにした場合と比べてよりくっきりと文字が描画されており、フレームレートも108 -> 141へと上がっていることが示されている。`}</p>
    <h2 {...{
      "id": "RTX-3060はRTX時代のニュースタンダードGPU",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#RTX-3060%E3%81%AFRTX%E6%99%82%E4%BB%A3%E3%81%AE%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%80%E3%83%BC%E3%83%89GPU",
        "aria-label": "RTX 3060はRTX時代のニュースタンダードGPU permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTX 3060はRTX時代のニュースタンダードGPU`}</h2>
    <p>{`実際にGTX1060、RTX2060、RTX 3060でWATCH DOGS LEGIONやCYBERPUNK 2077といった重いゲームをリアルタイムレイトレーシングをオンにして起動させたときのフレームレートは下記のようになる。`}</p>
    <Image {...props} name="rtx3060_perf_hq" caption="RTX 3060のパフォーマンス" mdxType="Image" />
    <p>{`RTX 3060では2020年後半に発売されたWatch DOGS LEGIONなどの`}<strong parentName="p"><em parentName="strong">{`重いゲームで60FPSを確保している`}</em></strong>{`一方で、GTX 1060では残念ながら10FPSを切り、まともにゲームができるレベルには達していない。`}</p>
    <p>{`このことから`}<strong parentName="p"><em parentName="strong">{`RTX 3060はリアルタイムレイトレーシングとDLSSに対応することにより、次世代の映像を楽しむための標準GPU`}</em></strong>{`と結論付けることができる。`}</p>
    <p>{`RTX 2060もリアルタイムレイトレーシングとDLSS対応はしているものの、最新のゲームをRTXオンで遊ぶにはパワー不足な感は否めないだろう。`}</p>
    <h2 {...{
      "id": "RTX-3060の設計・スペック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#RTX-3060%E3%81%AE%E8%A8%AD%E8%A8%88%E3%83%BB%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF",
        "aria-label": "RTX 3060の設計・スペック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTX 3060の設計・スペック`}</h2>
    <p>{`RTXの基本スペックについても触れておこう。スペック表を公式サイトから抜粋すると次のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>RTX 3070</th>
      <th>RTX 3060</th>
      <th>RTX 2060</th>
      <th>GTX 1060</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>NVIDIA CUDA コア</td>
      <td>5888</td>
      <td>3584</td>
      <td>1920</td>
      <td>1280</td>
    </tr>
    <tr>
      <td>クロック (GHz)	</td>
      <td>1.73</td>
      <td>1.78</td>
      <td>1.68</td>
      <td>1.71</td>
    </tr>
    <tr>
      <td>メモリサイズ</td>
      <td>8GB</td>
      <td>12GB</td>
      <td>6GB</td>
      <td>6GB</td>
    </tr>
    <tr>
      <td>メモリタイプ</td>
      <td>GDDR6</td>
      <td>GDDR6</td>
      <td>GDDR6</td>
      <td>GDDR5</td>
    </tr>
    <tr>
      <td>アーキテクチャ</td>
      <td>Ampere</td>
      <td>Ampere</td>
      <td>Turing</td>
      <td>Pascal</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "CUDAコア",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CUDA%E3%82%B3%E3%82%A2",
        "aria-label": "CUDAコア permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CUDAコア`}</h3>
    <p>{`単精度(32bit)浮動小数点の積和演算を行うユニット。RTX3060は3584個のCUDAコアを搭載しており、座標変換や陰影計算を行うシェーディング処理を並列で1クロックあたりに3584回こなす事ができる。`}</p>
    <p>{`RTX 3060は3584コア1.78Ghzでかつ積と和の演算を１クロック内に終えることができるため、3584x1.78(Ghz)x2で`}<strong parentName="p">{`1秒間に12.8兆回の計算をこなす事ができる`}</strong>{`。12.8TFLOPS(テラフロップス)と演算能力が書かれることもある。`}</p>
    <p>{`ちなみにCPUは5GHz程度と一つのコア当たりの演算能力は高いが、コア数はせいぜい10程度であるため、せいぜいGPUの演算能力の100分の１程度である。`}</p>
    <p>{`GPUは複雑な処理には向かないが、各ピクセルの色を決めるなどの独立した計算を高速にこなす事ができ、この核と言えるものがCUDAコアなのである。`}</p>
    <h3 {...{
      "id": "メモリタイプ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%82%BF%E3%82%A4%E3%83%97",
        "aria-label": "メモリタイプ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリタイプ`}</h3>
    <p>{`映像を出力するためには、`}<em parentName="p">{`フレームバッファ`}</em>{`というメモリ領域から映像情報を読み出す必要がある。`}</p>
    <p>{`GPUは計算した映像情報をリアルタイムで計算しフレームバッファに書き込む必要があり、メモリ領域の内容が高速で入れ替わる。`}</p>
    <p>{`このため容量はそこまで必要ではないものの、高速なメモリが必要となってくる。そこで考え出されたメモリがGDDRである。`}</p>
    <p>{`CPU用のメモリはDDRというタイプであり、GDDRも基本的な設計は変わらないが、ユーザによるメモリ増設やスロット拡張等の制約を取り除くことによりGPUに最適化されており、CPUの10倍以上の帯域幅を持つ。`}</p>
    <p>{`GDDR6はGDDRの6番目の規格でありGDDR5と比べて1.5倍程度帯域幅が向上している。`}</p>
    <h3 {...{
      "id": "Ampereアーキテクチャ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Ampere%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3",
        "aria-label": "Ampereアーキテクチャ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ampereアーキテクチャ`}</h3>
    <p>{`GTX 3000シリーズの基本設計の名前、GTX 2000シリーズではTuring(チューリング)という名前であった。`}</p>
    <p>{`Turingで対応したレイトレーシングの能力をより引き上げた設計となっているが大幅な設計変更は行っていない。`}</p>
    <p>{`Turing以降、レイトレーシングの膨大な計算量を如何に捌くかに焦点が置かれており、CUDAコアの他に`}<em parentName="p">{`RTコア`}</em>{`、`}<em parentName="p">{`Tensorコア`}</em>{`というプロセッサーを持っている。`}</p>
    <p>{`RTコアはレイトレーシングの計算に文字通り特化したコアであり、TensorコアはAI計算に特化したコアである。TensorコアはDLSSで用いられている。`}</p>
    <Image {...props} name="turing" caption="RTXシリーズの基本設計" mdxType="Image" />
    <p>{`上はRTコアとTensorコアが１フレームの生成にどのように使われるかを示した図である。`}</p>
    <p>{`RayTracing特有の計算をレンダリングパイプラインの途中で行い(緑色)、AI学習によりピクセルの色を推定すること(紫色)により、フレーム生成時間を短縮していることが見てとれる。`}</p>
    <h3 {...{
      "id": "RTX-3060の演算能力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#RTX-3060%E3%81%AE%E6%BC%94%E7%AE%97%E8%83%BD%E5%8A%9B",
        "aria-label": "RTX 3060の演算能力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTX 3060の演算能力`}</h3>
    <p>{`NVIDIAの発表から抜粋してきたが、RTX 3060の３つのプロセッサーの演算能力は次のように示されている。`}</p>
    <Image {...props} name="rtx3060_spec" caption="RTX 3060の演算能力" mdxType="Image" />
    <p>{`Shaderの13TFLOPSは12.8TFLOPSを四捨五入しただけで上で述べたCUDAコアの演算能力である。一方で、RTコアの演算能力は25TFLOPS、Tensorコアは101TFLOPSとなっている。`}</p>
    <p>{`Tensorコアの演算能力が非常に高く思えるが、`}<strong parentName="p"><em parentName="strong">{`FLOPSという単位は浮動小数点の演算回数を示した値で、その精度については関与しない`}</em></strong>{`。`}</p>
    <p>{`AI推論には演算の精密さが不要ということ先に述べた通りであるが、精度を落として計算速度を向上させていることがFLOPSが高い理由である。`}</p>
    <p>{`FLOPSが高いからといって、必ずしも精密で高速な演算が可能になるわけではないことには注意が必要である。`}</p>
    <h2 {...{
      "id": "RTX-3060は幅広いユーザが対象",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#RTX-3060%E3%81%AF%E5%B9%85%E5%BA%83%E3%81%84%E3%83%A6%E3%83%BC%E3%82%B6%E3%81%8C%E5%AF%BE%E8%B1%A1",
        "aria-label": "RTX 3060は幅広いユーザが対象 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTX 3060は幅広いユーザが対象`}</h2>
    <p>{`最後に、GPUの購入で迷っている人へ、下記の理由からRTX 3060は幅広いユーザが最初に検討すべきGPUである。`}</p>
    <List mdxType="List">
      <li>あらゆるゲームをRTX ONで快適に遊ぶことができる</li>
      <li>高いコストパフォーマンス</li>
      <li>ゲーミングモニターなしでも楽しめる</li>
    </List>
    <p>{`まず、DLSSの技術を使いRTXをONとした状態でも非常に滑らかな60FPSをキープしてゲームを遊ぶことができる。`}</p>
    <p>{`次に`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスがRTX 3000シリーズの中では断トツで高く`}</em></strong>{`、半導体の調達状況にも左右されるが希望小売価格として329ドルから購入することができる。`}</p>
    <p>{`もっとも日本だと割高な価格設定がされ安くても５万円ぐらいとなってしまうのであるが、GPUの中では手の届きやすい範囲だと言える。`}</p>
    <p>{`最後に60FPSまでは全てのモニターが対応しているため、通常の作業用モニターでも能力をフル活用することができる。`}</p>
    <p>{`RTX 3070以上はRTXをONにした状態でも60FPSを優に超えてくるためゲーミングモニターを購入しなければ能力を常に持て余す状態になってしまう。必ず購入しよう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      